import Decimal from "decimal.js"
import * as math from "mathjs"

export const calculate = (expression: string): number => {
	try {
		const node = math.parse(expression)

		const evalNode = (node: math.MathNode): Decimal => {
			switch (node.type) {
				case "OperatorNode": {
					const operatorNode = node as math.OperatorNode
					const left = evalNode(operatorNode.args[0])
					const right = evalNode(operatorNode.args[1])

					switch (operatorNode.op) {
						case "+":
							return left.plus(right)
						case "-":
							return left.minus(right)
						case "*":
							return left.times(right)
						case "/":
							return left.div(right)
						case "^":
							return left.pow(right)
						default:
							throw new Error(`Unknown operator: ${operatorNode.op}`)
					}
				}
				case "ConstantNode": {
					const constantNode = node as math.ConstantNode
					return new Decimal(constantNode.value as number)
				}
				case "ParenthesisNode": {
					const parenthesisNode = node as math.ParenthesisNode
					return evalNode(parenthesisNode.content)
				}
				default:
					throw new Error(`Unknown AST node: ${node.type}`)
			}
		}

		return evalNode(node).toNumber()
	} catch (error) {
		throw new Error("Invalid expression")
	}
}

export const add = (...values: (number | string)[]) => calculate(values.join("+"))
export const subtract = (...values: (number | string)[]) => calculate(values.join("-"))
export const multiply = (...values: (number | string)[]) => calculate(values.join("*"))
export const divide = (...values: (number | string)[]) => calculate(values.join("/"))
export const pow = (...values: (number | string)[]) => calculate(values.join("^"))
