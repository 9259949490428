import React, { ChangeEvent } from "react"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"
import Skeleton from "react-loading-skeleton"

import Input from "components/NewUI/Input"
import commonMessages from "messages/common"
import styles from "components/NewUI/Terminal/FunctionalPanel/OrderValue/index.module.scss"
import { TradeActionsEnum } from "types/exchange"

const OrderQty: React.FC<{
	changeOrderQty: (value: string) => void
	tradeAction: TradeActionsEnum
	blurOrderQty: (value: string) => void
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleSubmit?: any
	baseCurrencyCode: string
	amount: string
	qtyError?: string
	isLoaded?: boolean
}> = ({
	tradeAction,
	baseCurrencyCode,
	amount,
	blurOrderQty,
	handleSubmit,
	changeOrderQty,
	qtyError,
	isLoaded,
}) => {
	const { formatMessage } = useIntl()

	const onChangeOrderQty = (ev: ChangeEvent<HTMLInputElement>) => changeOrderQty(ev.target.value)

	const onBlurOrderQty = (ev: ChangeEvent<HTMLInputElement>) => blurOrderQty(ev.target.value)

	return (
		<div className={styles.orderValue}>
			{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
			<label className={styles.orderValue__qty}>
				<span className={styles.orderValue__labelText}>
					{formatMessage(commonMessages.quantity)}
				</span>

				{isLoaded ? (
					<Input
						name={`${tradeAction}-amount`}
						type="text"
						postfixText={baseCurrencyCode}
						placeholder={Number(amount) === 0 ? "0" : ""}
						value={amount}
						onChange={onChangeOrderQty}
						onBlur={onBlurOrderQty}
						onEnter={handleSubmit}
						error={qtyError}
					/>
				) : (
					<Skeleton height={40} borderRadius={8} />
				)}
			</label>
		</div>
	)
}

export default observer(OrderQty)
